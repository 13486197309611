<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">學潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">水肺教練培訓</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">

            <!-- 課程介紹 -->
            <div>
                <br>
                <img src="../../../../assets/Image/Data/DCS_intro.png" width="60%" style="display:block; margin:auto;">
                <br>

                <table borderless="true">
                    <tr>
                        <td class="fs-6">
                            SSI從1970年開始就一直是水肺教育系統的領導者。SSI領導級課程旨在提供潛水領域更優質的師資：一群充滿教學熱情並更重視安全技巧的領導級。<br>
                            <br>
                            想要跟上我們的腳步嗎？從專業導潛開始，再完成科學潛水專長課程，接著進行助理教練與教練班的培訓，一但通過教練考核(IE)的關鍵課程，
                            就能成為一個國際潛水教練， 開啟你全新的璀璨專業潛水人生！
                        </td>
                    </tr>
                </table>
                <br>
                <ul class="fs-6" style="color: green;">
                    <li>潛水長套裝：DG+SOD(DM)，優惠套裝價 45,000元</li>
                    <li>助理教練套奘：DG+SOD(DM)+AI 原價 99,000 元，優惠套裝價 89,000 元</li>
                    <li>教練套奘：DG+SOD(DM)+AI+ITC 原價 141,000 元，優惠套裝價 120,000 元</li>
                    <li>教練考試：IE 費用未定(每年實際考試時確認)</li>
                </ul>
            </div>
            <br>
            <br>
            <!-- 導潛DG -->
            <div>
                <br>
                <img src="../../../../assets/Image/Data/DG.png" width="60%" style="display:block; margin:auto;">
                <br>

                <h3 style="font-family:DFKai-sb;">導潛DG</h3>

                <table borderless="true">
                    <tr>
                        <td class="fs-6">
                            本課程的教學主要著重在實務技能的練習以及彈性的潛水背景教學，目標就是要把你培育成一位準備充分且多才多藝的導潛。 只要通過了浮潛教練課程，
                            專業導潛還可以教導並且核發浮潛證！。
                            <br>
                            <br>
                        </td>
                    </tr>                            
                    <tr>
                        <td class="fs-6" style="font-weight:bold;">
                            學科：導潛壓力與救援、團隊管理、領導潛水與專長潛水、SSI的職業生涯。<br>
                            術科：水性與各項潛水技巧評估、壓力控制、潛點地圖、潛水簡報、夜潛/深潛與導潛實習，筆試。
                        </td>
                    </tr>
                </table>
                <br>
                <ul class="fs-6" style="color: green;">
                    <li>學費 35,000 元，不含SSI領導級線上登錄費 6,000 元（升等領導級時需登錄線上作業系統）。</li>
                    <li>費用包含：線上教材、國際數位證照申請、教練費、實習費用與保險費。</li>
                    <li>實習日期視海況安排，不限定於開課的6天內完成</li>
                    <li>本課程暫不開放移地訓練</li>
                </ul>
            </div>
            <br>
            <br>
            <!-- 潛水長 DM -->
            <div>
                <br>
                <img src="../../../../assets/Image/Data/DM.png" width="60%" style="display:block; margin:auto;">
                <br>

                <h3 style="font-family:DFKai-sb;">潛水長 DM</h3>

                <table borderless="true">
                    <tr>
                        <td class="fs-6">
                            只要具有導潛資格加上科學潛水專長，您就可以成為潛水長了！你將具有以下資格：協助體驗潛水課程，在教練直接的監督下協助其進行游泳池/平靜水域訓練以及開放水域訓練。
                            <br>
                            <br>
                        </td>
                    </tr>                            
                    <tr>
                        <td class="fs-6" style="font-weight:bold;">
                            學科：潛水物理學、潛水生理學、減壓理論、裝備維修保養、海洋環境、筆試。
                            <br>
                            <br>
                        </td>
                    </tr>                            
                    <tr>
                        <td class="fs-6">
                            每年冬季開班, 共計2-3天。
                        </td>
                    </tr>
                </table>
                <br>
                <ul class="fs-6" style="color: green;">
                    <li>學費 10,000 元，不含SSI領導級線上登錄費 6,000 元。</li>
                    <li>費用包含：線上教材、國際數位證照申請、教練費。</li>
                    <li>完成本課程可取得科學潛水員專長SOD執照。</li>
                    <li>實習日期視海況安排，不限定於開課的6天內完成</li>
                    <li>本課程暫不開放移地訓練</li>
                </ul>
            </div>
            <br>
            <br>
            <!-- 助理潛水教練 AI -->
            <div>
                <br>
                <img src="../../../../assets/Image/Data/AI.png" width="60%" style="display:block; margin:auto;">
                <br>

                <h3 style="font-family:DFKai-sb;">助理潛水教練 AI</h3>

                <table borderless="true">
                    <tr>
                        <td class="fs-6">
                            助理潛水教練具有下列資格：帶領或是指導擁有認證的潛水員進行潛水；協助SSI 教練進行學科、游泳池、開放水域的教學；在教練直接的監督下自行指導學科或是游泳池訓練
                            (除了及緊急救援課程外)；執行SSI 水肺潛水技能復習課程、SSI 浮潛課程、SSI 體驗潛水課程(游泳池)；晉升為特殊專長訓練主管。
                            <br>
                            <br>
                        </td>
                    </tr>                            
                    <tr>
                        <td class="fs-6" style="font-weight:bold;">
                            學科：認識 SSI、作為一位 SSI 潛水專業人士的職責、課程行政作業、教授學科單元、教授水中訓練單元、助理教練課程。<br>
                            術科：水性與體能評估、OWD學科、泳池、海洋結訓之觀察與試教評估。<br>
                            <br>
                        </td>
                    </tr>                            
                    <tr>
                        <td class="fs-6">
                            每年冬季開班, 共計6天。
                        </td>
                    </tr>
                </table>
                <br>
                <ul class="fs-6" style="color: green;">
                    <li>學費 48,000 元。</li>
                    <li>費用包含：線上教材、國際數位證照申請、教練費。</li>
                </ul>
            </div>
            <br>
            <br>
            <br>
            <br>
            <!-- 開放水域潛水教練 OWI -->
            <div>
                <br>
                <img src="../../../../assets/Image/Data/OWI.png" width="60%" style="display:block; margin:auto;">
                <br>

                <h3 style="font-family:DFKai-sb;">開放水域潛水教練 OWI</h3>

                <table borderless="true">
                    <tr>
                        <td class="fs-6">
                            只要你是助理教練(AI)並且通過了教練培訓課程(ITC)與教練評估(IE)後，你就是正式的SSI開放水域潛水教練(OWI)。
                            成為開放水域潛水教練後，可以進行以下教學並給予認證：室內潛水員、水肺潛水員、青少年開放水域潛水員、開放水域潛水員、
                            進階探索潛水員、水肺技巧複習、體驗潛水、高氧潛水、壓力與救援潛水以及導潛課程。
                            <br>
                            <br>
                        </td>
                    </tr>                            
                    <tr>
                        <td class="fs-6" style="font-weight:bold;">
                            學科：12堂教練基礎課程與OWD試講試講、3項專長教練培訓 ( 高氧、壓力與救援、導潛 )。<br>
                            術科：水性與體能評估、OWD與專長課程之學科、泳池、海洋結訓之觀察與試教評估。<br>
                            <br>
                        </td>
                    </tr>                            
                    <tr>
                        <td class="fs-6">
                            每年冬季開班, 共計6天。
                        </td>
                    </tr>
                </table>
                <br>
                <ul class="fs-6" style="color: green;">
                    <li>費 42,000元。(不含教練考試之相關費用)。</li>
                    <li>費用包含：教材、證照申請、泳池費用、教練費。</li>
                    <li>費用不含：交通、餐點、教練評估(IE)費用 (2022年價格為20,000)。</li>
                    <li>實習日期視海況安排，不限定於開課的6天內完成</li>
                    <li>本課程暫不開放移地訓練</li>
                </ul>
            </div>
            <!-- 報名方式 -->
            <div>
                <br>
                <br>
                <br>
                <app-paymentmethod></app-paymentmethod>
                <br>
            </div>
            <!-- 學前須知 -->
            <div>
                <h3 style="font-family:DFKai-sb;">學前須知</h3>
                <ol class="fs-6">
                    <li>領導級課程需完成各項評估並通過才能核發證照。本課程非保證班。</li>
                    <li>參與課程之後選員應配合訓練官之課程指導，積極完成各項課程準備。</li>
                    <li>課程中會視候選員狀況調整課程時間與內容，報名本課程視為了解課程具有彈性調整的必要，並接受可能產生之補課費用。</li>
                    <li>單一課程須於６個月內完成。</li>
                    <li>若學員自行放棄課程，本訓練中心將不予退費(包含訂金)</li>
                    <li>課程前須開立醫生證明可正常從事潛水活動，故本課程不接受以身體因素申請退費。</li>
                    <li>海洋實習地點為東北角或各移地結訓地點，往返之交通請自行處理。</li>
                    <li>欲參與教練評估Instructor Evaluate (IE) 需額外負擔教練評估費用。</li>
                </ol>
            </div>

        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>