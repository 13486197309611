<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">學潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Freediver</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <button class="fs-6" ngbNavLink>夏季假日班</button>
                    <ng-template ngbNavContent class="custom-class">
                        <br />
                        <img src="../../../assets/Image/Data/FD_Text1.png" width="99%" style="display: block; margin: auto" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
                        <br />
                        <h3 style="white-space:nowrap; overflow:hidden; text-overflow:ellipsis">Freediver</h3>
                        <hr />
                        <!-- 夏季假日班 課程內容 -->
                        <div>
                            <table borderless="true">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                            <h3 style="font-family:DFKai-sb;">【課程內容】</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="1%"> <!-- style="vertical-align:text-top;"> -->
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                學科（6hr）<br />
                                                裝備介紹／自潛生理學／自潛技巧／海洋環境
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="2%" style="vertical-align:text-top;">
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                泳池訓練（4hr）<br />
                                                閉氣練習／踢動技巧／平潛練習／潛伴制度
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="2%" style="vertical-align:text-top;">
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                海洋訓練（8hr）<br />
                                                5m 划手／無面鏡上升／救援／鴨式技巧<br />
                                                10m 划手／鴨式下潛／恆重下潛
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <img src="../../../../assets/Image/Data/FD_Text2.png" width="99%" style="display: block; margin: auto" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
                        </div>
                        <!-- 夏季假日班 課程特色 -->
                        <div>
                            <table borderless="true">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                            <h3 style="font-family:DFKai-sb;"><br>【課程特色】</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="1%" style="vertical-align:text-top;">
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                教練人數比 1:3~4，教練全程盯著你。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                數位教材伴你一生，不用擔心知識忘記。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                學員上課期間購買潛水器材享有學員優惠價。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                如深度考核無法通過，即簽發Pool等級，持照可先至泳池練習。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                泳池團練、潛立方、墾丁、小琉球、綠島、蘭嶼，出團活動iDiving幫你揪人。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                本店學生一年內POOL等級補考LV1免裝備租借、證照升級費用，只需繳交補考費。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                            <img src="../../../../assets/Image/Data/FD_Text3.png" width="99%" style="display:block; margin:auto;" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
                        </div>
                        <!-- 夏季假日班 課程規劃 -->
                        <div>
                            <table borderless="true">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                            <h3 style="font-family:DFKai-sb;"><br>【夏季假日課程規劃】</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="2%">
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                DAY 1 | 學科 19:00~22:00<br>
                                            </p>
                                            <hr>
                                            <p>
                                                自由潛水生理學、海洋環境
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 2 | 學科 19:00~22:00<br>
                                            </p>
                                            <hr>
                                            <p>
                                                裝備介紹、自由潛水技巧<br>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 3 | 泳池術科 11:00~15:00<br>
                                            </p>
                                            <hr>
                                            <p>
                                                裝備使用、閉氣練習、踢動技巧<br>
                                                平潛練習、潛伴制度
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 4 | 海洋 08:00~17:00<br>
                                            </p>
                                            <hr>
                                            <p>
                                                平壓技巧、5-10m划手、無面鏡上升<br>
                                                救援技巧、鴨式技巧
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 5 & 6 | 海洋 08:00~17:00<br>
                                            </p>
                                            <hr>
                                            <p>
                                                10m划手、無面鏡上升、10m救援<br>
                                                10m恆重下潛，同時展現適當身體技巧<br>
                                                綜合考核
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <p class="fs-6" style="color:red;">
                                                <br>註:實際下水時間將依照現場情況調整
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                            <div id="carouselExampleIndicators" class="carousel slide">
                                <div class="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                </div>
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <img src="../../../../assets/Image/Data/FD_Weekend_Schedule_01.png" class="d-block w-100" alt="...">
                                    </div>
                                    <div class="carousel-item">
                                        <img src="../../../../assets/Image/Data/FD_Weekend_Schedule_02.png" class="d-block w-100" alt="...">
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                        <!-- 夏季假日班 課程學費 -->
                        <!-- 方案一  -->
                        <div class="row">
                            <div class="col">
                                <br>
                                <br>
                                <h3 style="font-family:DFKai-sb;">課程學費</h3>
                                <table borderless="true">
                                    <tbody>
                                        <tr>
                                            <td colspan="3" class="fs-6">
                                                <a style="color: green;"><br>Freediver $14,000 元</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" class="fs-6">
                                                <ul>
                                                    <li style="color: red;">現金優惠:折扣 300 元。</li>
                                                    <li style="color: red;">人數優惠:兩人一起報名更優惠，每人優惠 700 元。三人同樂優惠加倍，每人優惠
                                                        1,200 元。</li>
                                                    <li>費用包含:SSI線上教材、國際數位證照申請、教練費、泳池場地費、東北角海洋實習及保險、訓練期間之裝備。</li>
                                                    <li>費用不含:海洋實習交通費、餐食住宿費。</li>
                                                    <li>課程輕裝優惠，面鏡、呼吸管、配重帶原價2,580元，配合課程只需<a style="color: red;">加價1,400</a>元。</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br>
                                <img src="../../../../assets/Image/Data/FD_17.png" width="100%" />
                                <ul>
                                    <li>「本店OWD學生」報名 Freediver 可享 $1,000 元折扣</li>
                                    <li>他店學生來iDiving 進行後續課程，欲取得 Freediver 自由潛水員 課程贈品，享會員價</li>
                                    <li>往後參加自潛活動費用:每次 750 元</li>
                                </ul>
                            </div>
                        </div>
                        <!-- 最新優惠 方案  -->
                        <br>
                        <div class="row">
                            <div class="col bg-light">
                                <br>
                                <h3 style="font-family:DFKai-sb;">最新優惠</h3>
                                <br>
                                <div class="d-block grey lighten-3">
                                    <br>
                                    <table borderless="true">
                                        <tbody>
                                            <tr>
                                                <td colspan="3" class="fs-6">
                                                    <p>
                                                        🌞夏季涼一夏優惠方案🌞<br>
                                                        凡是在2024/9/1前<br>
                                                        完成自由潛水員夏季課程報名<br>
                                                        🎉7月份優惠2500元<br>
                                                        🎉8月份優惠2000元<br>
                                                        <br>
                                                        <br>
                                                        ⚠️注意⚠️<br>
                                                        ＊本方案優惠不得與其他折扣、優惠、行銷活動合併使用。<br>
                                                        ＊定金可匯款/linepay/刷卡，尾款需以現金繳交。<br>
                                                        ＊需依照課程排程完整期別完成課程。<br>
                                                        ＊iDiving 保留對本活動文字的解釋權利。<br>
                                                        <br>
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <br>
                                <table borderless="true">
                                    <tbody>
                                        <tr>
                                            <td colspan="3" class="fs-6">
                                                <p>
                                                    灰姑娘/灰王子專案:
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" class="fs-6">
                                                <p>
                                                    即日起只要現場試穿特定蛙鞋，腳型適合者<br>
                                                    即可使用課程原價(15400)<br>
                                                    帶走全新蛙鞋及完整SSI Freediver 課程，但不附贈王子唷~
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="fs-6" colspan="3">
                                                <p>注意</p>
                                                <ul>
                                                    <li>數量有限、不可挑色。</li>
                                                    <li>鞋型尺寸 22.5-26cm。</li>
                                                    <li>不得與其他優惠並用。</li>
                                                    <li>課程期別可選(1-3期)，但需先行帶走您的玻璃鞋。</li>
                                                    <li>須臨櫃以現金繳交全額尾款，方能享本優惠。</li>
                                                    <li>蛙鞋帶離店面恕無法退款。</li>
                                                    <li>iDiving 保留對本活動文字的解釋權利。</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br>
                            </div>
                        </div>
                        <br>
                        <br>
                        <!-- 夏季假日班 報名方式 -->
                        <div>
                            <app-paymentmethod></app-paymentmethod>
                        </div>
                        <!-- 夏季假日班 考核標準 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">考核標準</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <p>學員結訓均需達到學科與術科課程標準之評鑑</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="50px" style="vertical-align:text-top;">
                                            <p>學科:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>需通過筆試測驗（成績達80分以上），未達標準需於檢討後重考。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="50px" style="vertical-align:text-top;">
                                            <p>泳池:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>動態平潛30m。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="50px" style="vertical-align:text-top;">
                                            <p>深度:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>10m划手上升、10m無面鏡上升、10m救援、10m恆重下潛，同時展現適當身體技巧。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" width="4%" style="vertical-align:text-top;">
                                            <p>註:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                1.Freediver 課程，如無法通過深度測驗，則降階簽發Pool Diver證照(須完成POOL學科、泳池考核)
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            <p> </p>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                2.鼓勵學員取得 Freediver 證照取得Pool 一年內補考，只需繳交補考費用，通過後證照升級費用我們負擔。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            <p> </p>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                3.夏季海洋補考 750/次，冬季潛立方補考 2500/3hr。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 夏季假日班 上課地點 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">上課地點</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="100px" style="vertical-align:text-top;">
                                            <p>教室學科:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>iDiving士林前港店</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            <p>泳池術科:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>各配合泳池（需先到iDiving集合）</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            <p>海洋實習: </p>
                                        </td>
                                        <td class="fs-6">
                                            <p>夏季東北角（iDiving 東北角龍洞店）冬季潛立方（台中市西屯區安和西路 69 號）或 各移地結訓地</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <p>註: </p>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                ＊如遇天候不佳或泳池場地整修等，上課地點與時間可能視狀況調整，若有額外增加的場地費用，本中心將自行吸收（大台北區轉換場地之費用，台中泳池費用），不會另外收費。
                                                墾丁離島移地訓練產生之費用需自付。
                                                <br>＊學員不可因課程之"地點/時間"有調整而拒學並要求退費。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 夏季假日班 特約課程 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">特約課程</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>學科部份，每另外約一日課程加價 300 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <p>術科部份，每另外約一日課程加價 500 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>三人同行，一起約定特殊時間上課，不須額外加價。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>指定教練費: 指定教練，加價 500 元/日，教練如果已安排既定課程或是出團，恕無法指定。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 夏季假日班 更改辦法 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">更改辦法</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>課程須於開始上課後的兩個月內完成。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課日期之更改須在 10 天前告知</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 夏季假日班 取消辦法 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">取消辦法</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>若因身體因素而不適合從事潛水活動，並開立醫生證明者，繳交之費用退費方式如下: NT 3,000
                                                元訂金用做購買線上教材，無法退費。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                退費金額依據繳交之費用總額，扣除行政及教材費用各階段扣除費用參考如下:<br>
                                                學員自行放棄課程，本訓練中心將不予退費。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <br>

                            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 fs-6">
                                <ng-container matColumnDef="position">
                                    <th mat-header-cell *matHeaderCellDef class="fs-6"> </th>
                                    <td mat-cell *matCellDef="let element" class="fs-6"> {{element.position}} </td>
                                </ng-container>
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef class="fs-6"> 30天前，<br>退回全新贈品 </th>
                                    <td mat-cell *matCellDef="let element" class="fs-6"> {{element.name}} </td>
                                </ng-container>
                                <ng-container matColumnDef="weight">
                                    <th mat-header-cell *matHeaderCellDef class="fs-6"> 30天"內"，<br>退回全新贈品 </th>
                                    <td mat-cell *matCellDef="let element" class="fs-6"> {{element.weight}} </td>
                                </ng-container>
                                <ng-container matColumnDef="symbol">
                                    <th mat-header-cell *matHeaderCellDef class="fs-6"> 已上過學科 </th>
                                    <td mat-cell *matCellDef="let element" class="fs-6"> {{element.symbol}} </td>
                                </ng-container>
                                <ng-container matColumnDef="symbol1">
                                    <th mat-header-cell *matHeaderCellDef class="fs-6"> 已上過泳池 </th>
                                    <td mat-cell *matCellDef="let element" class="fs-6"> {{element.symbol1}} </td>
                                </ng-container>
                                <ng-container matColumnDef="symbol2">
                                    <th mat-header-cell *matHeaderCellDef class="fs-6"> 去過海洋 </th>
                                    <td mat-cell *matCellDef="let element" class="fs-6"> {{element.symbol2}} </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>

                            <br>

                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a ngbNavLink>夏季平日班</a>
                    <ng-template ngbNavContent>
                        <br />
                        <img src="../../../assets/Image/Data/FD_Text1.png" width="99%" style="display: block; margin: auto" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
                        <br />
                        <h3 style="white-space:nowrap; overflow:hidden; text-overflow:ellipsis">Freediver</h3>
                        <hr />
                        <!-- 夏季平日班 課程內容 -->
                        <div>
                            <table borderless="true">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                            <h3 style="font-family:DFKai-sb;">【課程內容】</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="1%" style="vertical-align:text-top;">
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                學科（6hr）<br>
                                                裝備介紹／自潛生理學／自潛技巧／海洋環境
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="2%" style="vertical-align:text-top;">
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                泳池訓練（4hr）<br>
                                                閉氣練習／踢動技巧／平潛練習／潛伴制度
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="2%" style="vertical-align:text-top;">
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                海洋訓練（8hr）<br>
                                                5m 划手／無面鏡上升／救援／鴨式技巧<br />
                                                10m 划手／鴨式下潛／恆重下潛
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <img src="../../../../assets/Image/Data/FD_Text2.png" width="99%" style="display: block; margin: auto" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
                        </div>
                        <!-- 夏季平日班 課程特色 -->
                        <div>
                            <table borderless="true">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                            <h3 style="font-family:DFKai-sb;"><br>【課程特色】</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="1%" style="vertical-align:text-top;">
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                教練人數比 1:3~4，教練全程盯著你。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                數位教材伴你一生，不用擔心知識忘記。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                學員上課期間購買潛水器材享有學員優惠價。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                如深度考核無法通過，即簽發Pool等級，持照可先至泳池練習。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                泳池團練、潛立方、墾丁、小琉球、綠島、蘭嶼，出團活動iDiving幫你揪人。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                本店學生一年內POOL等級補考LV1免裝備租借、證照升級費用，只需繳交補考費。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                            <img src="../../../../assets/Image/Data/FD_Text3.png" width="99%" style="display:block; margin:auto;" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
                        </div>
                        <!-- 夏季平日班 課程規劃 -->
                        <div>
                            <table borderless="true">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                            <h3 style="font-family:DFKai-sb;"><br>【夏季平日班課程規劃】</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="2%">
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                DAY 1 | 學科 + 泳池術科 09:00~17:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                自由潛水生理學、海洋環境。<br>
                                                <br>
                                                裝備使用、閉氣練習、踢動技巧<br>
                                                平潛練習、潛伴制度。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 2 | 學科 + 海洋 09:00~17:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                裝備介紹、自由潛水技巧。<br>
                                                <br>
                                                平壓技巧、5m划手、無面鏡上升<br>
                                                救援技巧、鴨式技巧。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 3 | 海洋 09:00~17:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                10m划手、無面鏡上升、鴨式下潛、10m救援<br>
                                                10m恆重下潛，同時展現適當身體技巧。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 4 | 海洋 08:30~12:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                綜合考核。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <p class="fs-6" style="color:red;">
                                                <br>註:實際下水時間將依照現場情況調整
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                            <div id="carouselExampleIndicators" class="carousel slide">
                                <div class="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                </div>
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <img src="../../../../assets/Image/Data/FD_Weekdays_Schedule_02.png" class="d-block w-100" alt="...">
                                    </div>
                                    <div class="carousel-item">
                                        <img src="../../../../assets/Image/Data/FD_Weekdays_Schedule_01.png" class="d-block w-100" alt="...">
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                        <!-- 夏季平日班 課程學費 -->
                        <!-- 方案一  -->
                        <div class="row">
                            <div class="col">
                                <br>
                                <br>
                                <h3 style="font-family:DFKai-sb;">課程學費</h3>
                                <table borderless="true">
                                    <tbody>
                                        <tr>
                                            <td colspan="3" class="fs-6">
                                                <p style="color: green;"><br>Freediver $14,000 元</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" class="fs-6">
                                                <ul>
                                                    <li style="color: red;">現金優惠:折扣 $300 元。</li>
                                                    <li style="color: red;">人數優惠:兩人一起報名更優惠，每人優惠 700 元。三人同樂優惠加倍，每人優惠
                                                        1,200 元。
                                                    </li>
                                                    <li>費用包含:SSI線上教材、國際數位證照申請、教練費、泳池場地費、東北角海洋實習及保險、訓練期間之裝備。</li>
                                                    <li>費用不含:海洋實習交通費、餐食住宿費。</li>
                                                    <li>課程輕裝優惠，面鏡、呼吸管、配重帶原價2,580元，配合課程只需<a style="color: red;">加價1,400</a>元。
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3">
                                                <img src="../../../../assets/Image/Data/FD_17.png" width="100%" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="fs-6" colspan="3">
                                                <ul>
                                                    <li>「本店OWD學生」報名 Freediver 可享 1,000 元折扣</li>
                                                    <li>他店學生來iDiving 進行後續課程，欲取得 Freediver 自由潛水員 課程贈品，享會員價</li>
                                                    <li>往後參加自潛活動費用:每次 750 元</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br>
                            </div>
                        </div>
                        <!-- 最新優惠 方案  -->
                        <div class="row">
                            <div class="col bg-light">
                                <br>
                                <h3 style="font-family:DFKai-sb;">最新優惠</h3>
                                <br>
                                <div class="d-block grey lighten-3">
                                    <br>
                                    <table borderless="true">
                                        <tbody>
                                            <tr>
                                                <td colspan="3" class="fs-6">
                                                    <p>
                                                        🌞夏季涼一夏優惠方案🌞<br>
                                                        凡是在2024/9/1前<br>
                                                        完成自由潛水員夏季課程報名<br>
                                                        🎉7月份優惠2500元<br>
                                                        🎉8月份優惠2000元<br>
                                                        <br>
                                                        <br>
                                                        ⚠️注意⚠️<br>
                                                        ＊本方案優惠不得與其他折扣、優惠、行銷活動合併使用。<br>
                                                        ＊定金可匯款/linepay/刷卡，尾款需以現金繳交。<br>
                                                        ＊需依照課程排程完整期別完成課程。<br>
                                                        ＊iDiving 保留對本活動文字的解釋權利。<br>
                                                        <br>
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <br>
                                <table borderless="true">
                                    <tbody>
                                        <tr>
                                            <td colspan="3" class="fs-6">
                                                <p>
                                                    灰姑娘/灰王子專案:
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" class="fs-6">
                                                <p>
                                                    即日起只要現場試穿特定蛙鞋，腳型適合者<br>
                                                    即可使用課程原價(15400)<br>
                                                    帶走全新蛙鞋及完整SSI Freediver 課程，但不附贈王子唷~
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="fs-6" colspan="3">
                                                <p>注意</p>
                                                <ul>
                                                    <li>數量有限、不可挑色。</li>
                                                    <li>鞋型尺寸 22.5-26cm。</li>
                                                    <li>不得與其他優惠並用。</li>
                                                    <li>課程期別可選(1-3期)，但需先行帶走您的玻璃鞋。</li>
                                                    <li>須臨櫃以現金繳交全額尾款，方能享本優惠。</li>
                                                    <li>蛙鞋帶離店面恕無法退款。</li>
                                                    <li>iDiving 保留對本活動文字的解釋權利。</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br>
                            </div>
                        </div>
                        <!-- 夏季平日班 報名方式 -->
                        <div>
                            <app-paymentmethod></app-paymentmethod>
                        </div>
                        <!-- 夏季平日班 考核標準 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">考核標準</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <p>學員結訓均需達到學科與術科課程標準之評鑑</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="50px" style="vertical-align:text-top;">
                                            <p>學科:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>需通過筆試測驗（成績達80分以上），未達標準需於檢討後重考。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="50px" style="vertical-align:text-top;">
                                            <p>泳池:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>動態平潛30m。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="50px" style="vertical-align:text-top;">
                                            <p>深度:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>10m划手上升、10m無面鏡上升、10m救援、10m恆重下潛，同時展現適當身體技巧。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" width="4%" style="vertical-align:text-top;">
                                            <p>註:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                1. Freediver 課程，如無法通過深度測驗，則降階簽發Pool Diver證照(須完成POOL學科、泳池考核)
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            <p> </p>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                2.鼓勵學員取得 Freediver 證照取得Pool 一年內補考，只需繳交補考費用，通過後證照升級費用我們負擔。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            <p> </p>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                3.夏季海洋補考 750/次，冬季潛立方補考 2500/3hr。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 夏季平日班 上課地點 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">上課地點</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="100px" style="vertical-align:text-top;">
                                            <p>教室學科:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>iDiving士林前港店</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            <a>泳池術科:</a>
                                        </td>
                                        <td class="fs-6">
                                            <a>各配合泳池（需先到iDiving集合）</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            <p>海洋實習: </p>
                                        </td>
                                        <td class="fs-6">
                                            <p>夏季東北角（iDiving 東北角龍洞店）冬季潛立方（台中市西屯區安和西路 69 號）或 各移地結訓地</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <p>註: </p>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                ＊如遇天候不佳或泳池場地整修等，上課地點與時間可能視狀況調整，若有額外增加的場地費用，本中心將自行吸收（大台北區轉換場地之費用，台中泳池費用），不會另外收費。
                                                墾丁離島移地訓練產生之費用需自付。
                                                <br>＊學員不可因課程之"地點/時間"有調整而拒學並要求退費。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 夏季平日班 特約課程 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">特約課程</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>學科部份，每另外約一日課程加價 300 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <p>術科部份，每另外約一日課程加價 500 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>三人同行，一起約定特殊時間上課，不須額外加價。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>指定教練費: 指定教練，加價 500 元/日，教練如果已安排既定課程或是出團，恕無法指定。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 夏季平日班 更改辦法 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">更改辦法</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>課程須於開始上課後的兩個月內完成。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課日期之更改須在 10 天前告知</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 夏季平日班 取消辦法 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">取消辦法</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                若因身體因素而不適合從事潛水活動，並開立醫生證明者，繳交之費用退費方式如下: NT 3,000
                                                元訂金用做購買線上教材，無法退費。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                退費金額依據繳交之費用總額，扣除行政及教材費用各階段扣除費用參考如下:<br>
                                                學員自行放棄課程，本訓練中心將不予退費。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <br>

                            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 fs-6">
                                <ng-container matColumnDef="position">
                                    <th mat-header-cell *matHeaderCellDef class="fs-6"> </th>
                                    <td mat-cell *matCellDef="let element" class="fs-6"> {{element.position}} </td>
                                </ng-container>
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef class="fs-6"> 30天前，<br>退回全新贈品 </th>
                                    <td mat-cell *matCellDef="let element" class="fs-6"> {{element.name}} </td>
                                </ng-container>
                                <ng-container matColumnDef="weight">
                                    <th mat-header-cell *matHeaderCellDef class="fs-6"> 30天"內"，<br>退回全新贈品 </th>
                                    <td mat-cell *matCellDef="let element" class="fs-6"> {{element.weight}} </td>
                                </ng-container>
                                <ng-container matColumnDef="symbol">
                                    <th mat-header-cell *matHeaderCellDef class="fs-6"> 已上過學科 </th>
                                    <td mat-cell *matCellDef="let element" class="fs-6"> {{element.symbol}} </td>
                                </ng-container>
                                <ng-container matColumnDef="symbol1">
                                    <th mat-header-cell *matHeaderCellDef class="fs-6"> 已上過泳池 </th>
                                    <td mat-cell *matCellDef="let element" class="fs-6"> {{element.symbol1}} </td>
                                </ng-container>
                                <ng-container matColumnDef="symbol2">
                                    <th mat-header-cell *matHeaderCellDef class="fs-6"> 去過海洋 </th>
                                    <td mat-cell *matCellDef="let element" class="fs-6"> {{element.symbol2}} </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>

                            <br>

                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3">
                    <a ngbNavLink>冬季班</a>
                    <ng-template ngbNavContent>
                        <br />
                        <img src="../../../assets/Image/Data/FD_Text1.png" width="99%" style="display: block; margin: auto" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
                        <br />
                        <h3 style="white-space:nowrap; overflow:hidden; text-overflow:ellipsis">Freediver</h3>
                        <hr />
                        <!-- 冬季班 課程內容 -->
                        <div>
                            <table borderless="true">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                            <h3 style="font-family:DFKai-sb;">【課程內容】</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="1%" style="vertical-align:text-top;">
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                學科（6hr）<br>
                                                裝備介紹／自潛生理學／自潛技巧／海洋環境
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="2%" style="vertical-align:text-top;">
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                泳池訓練（4hr）<br>
                                                閉氣練習／踢動技巧／平潛練習／潛伴制度
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="2%" style="vertical-align:text-top;">
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                海洋訓練（8hr）<br>
                                                5m 划手／無面鏡上升／救援／鴨式技巧<br>
                                                10m 划手／鴨式下潛／恆重下潛
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <img src="../../../../assets/Image/Data/FD_Text2.png" width="99%" style="display: block; margin: auto" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
                        </div>
                        <!-- 冬季班 課程特色 -->
                        <div>
                            <table borderless="true">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                            <h3 style="font-family:DFKai-sb;"><br>【課程特色】</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="1%" style="vertical-align:text-top;">
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                教練人數比 1:3~4，教練全程盯著你。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                數位教材伴你一生，不用擔心知識忘記。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                學員上課期間購買潛水器材享有學員優惠價。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                如深度考核無法通過，即簽發Pool等級，持照可先至泳池練習。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                泳池團練、潛立方、墾丁、小琉球、綠島、蘭嶼，出團活動iDiving幫你揪人。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                本店學生一年內POOL等級補考LV1免裝備租借、證照升級費用，只需繳交補考費。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                            <img src="../../../../assets/Image/Data/FD_Text3.png" width="99%" style="display:block; margin:auto;" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
                        </div>
                        <!-- 冬季班 課程規劃 -->
                        <div>
                            <table borderless="true">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                            <h3 style="font-family:DFKai-sb;"><br>【冬季班課程規劃】</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="2%">
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                DAY 1 | 學科 19:00~22:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                自由潛水生理學、海洋環境
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 2 | 學科 19:00~22:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                裝備介紹、自由潛水技巧
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 3 | 泳池術科 13:00~16:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                裝備使用、閉氣練習、踢動技巧<br>
                                                平潛練習、潛伴制度
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 4 | 泳池術科 13:00~16:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                平壓技巧、5m划手、無面鏡上升<br>
                                                救援技巧、鴨式技巧
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 5 | 潛立方1天or移地2天 09:00~17:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                10m划手、無面鏡上升、10m救援<br>
                                                10m恆重下潛，同時展現適當身體技巧<br>
                                                綜合考核。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <p class="fs-6" style="color:red;">
                                                <br>註:實際下水時間將依照現場情況調整
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                            <img src="../../../../assets/Image/Data/FD_Winter_Schedule.png" width="99%" style="display:block; margin:auto;" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
                            <br>
                            <br>
                        </div>
                        <!-- 冬季班 課程學費 -->
                        <!-- 方案一  -->
                        <div class="row">
                            <div class="col">
                                <br>
                                <br>
                                <h3 style="font-family:DFKai-sb;">課程學費</h3>
                                <table borderless="true">
                                    <tbody>
                                        <tr>
                                            <td colspan="3" class="fs-6">
                                                <a style="color: green;"><br>Freediver $14,000 元</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" class="fs-6">
                                                <ul>
                                                    <li style="color: red;">現金優惠:折扣 300 元。</li>
                                                    <li style="color: red;">人數優惠:兩人一起報名更優惠，每人優惠 700 元。三人同樂優惠加倍，每人優惠
                                                        1,200 元。</li>
                                                    <li>費用包含:SSI線上教材、國際數位證照申請、教練費、泳池場地費、東北角海洋實習及保險、訓練期間之裝備。</li>
                                                    <li>費用不含:海洋實習交通費、餐食住宿費。</li>
                                                    <li>課程輕裝優惠，面鏡、呼吸管、配重帶原價2,580元，配合課程只需<a style="color: red;">加價1,400</a>元。</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br>
                                <img src="../../../../assets/Image/Data/FD_17.png" width="100%" />
                                <ul>
                                    <li>「本店OWD學生」報名 Freediver 可享 $1,000 元折扣</li>
                                    <li>他店學生來iDiving 進行後續課程，欲取得 Freediver 自由潛水員 課程贈品，享會員價</li>
                                    <li>往後參加自潛活動費用:每次 750 元</li>
                                </ul>
                                <br>
                                <div class="d-block grey lighten-3">
                                    <br>
                                    <table borderless="true">
                                        <tbody>
                                            <tr>
                                                <td colspan="3" class="fs-6">
                                                    <p>
                                                        🎉早鳥優惠方案🎉<br>
                                                        即日起(2/15)<br>
                                                        完成特定開課之 自由潛水員夏季課程報名<br>
                                                        優惠2500元：01-02期<br>
                                                        優惠2000元：03期<br>
                                                        <br>
                                                        <br>
                                                        ⚠️注意⚠️<br>
                                                        ＊本方案優惠不得與其他折扣、優惠、行銷活動合併使用。<br>
                                                        ＊需依照課程排程完成課程<br>
                                                        ＊須臨櫃以現金繳交全額尾款，方能享本優惠。<br>
                                                        ＊iDiving 保留對本活動文字的解釋權利。<br>
                                                        <br>
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <!-- 方案二  -->
                        <div class="row">
                            <div class="col d-block grey lighten-3">
                                <br>
                                <h3 style="font-family:DFKai-sb;">最新優惠</h3>
                                <br>
                                <table borderless="true">
                                    <tbody>
                                        <tr>
                                            <td colspan="3" class="fs-6">
                                                <p>
                                                    灰姑娘/灰王子專案:
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" class="fs-6">
                                                <p>
                                                    即日起只要現場試穿特定蛙鞋，腳型適合者<br>
                                                    即可使用課程原價(15400)<br>
                                                    帶走全新蛙鞋及完整SSI Freediver 課程，但不附贈王子唷~
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="fs-6" colspan="3">
                                                <p>注意</p>
                                                <ul>
                                                    <li>數量有限、不可挑色。</li>
                                                    <li>鞋型尺寸 22.5-26cm。</li>
                                                    <li>不得與其他優惠並用。</li>
                                                    <li>課程期別可選(1-3期)，但需先行帶走您的玻璃鞋。</li>
                                                    <li>須臨櫃以現金繳交全額尾款，方能享本優惠。</li>
                                                    <li>蛙鞋帶離店面恕無法退款。</li>
                                                    <li>iDiving 保留對本活動文字的解釋權利。</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br>
                            </div>
                        </div>
                        <br>
                        <br>
                        <!-- 冬季班 報名方式 -->
                        <app-paymentmethod></app-paymentmethod>
                        <!-- 冬季班 考核標準 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">考核標準</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <p>學員結訓均需達到學科與術科課程標準之評鑑</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="50px" style="vertical-align:text-top;">
                                            <p>學科:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>需通過筆試測驗（成績達80分以上），未達標準需於檢討後重考。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="50px" style="vertical-align:text-top;">
                                            <p>泳池:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>動態平潛30m、徒手推進15m。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="50px" style="vertical-align:text-top;">
                                            <p>深度:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>10m划手上升、10m無面鏡上升、10m救援、10m恆重下潛，同時展現適當身體技巧。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" width="4%" style="vertical-align:text-top;">
                                            <p>註:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                1. Freediver 課程，如無法通過深度測驗，則降階簽發Pool Diver證照(須完成POOL學科、泳池考核)
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            <p> </p>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                2.鼓勵學員取得 Freediver 自由潛水員 證照取得Pool 一年內補考，只需繳交補考費用，通過後證照升級費用我們負擔。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            <p> </p>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                3.夏季海洋補考 750/次，冬季潛立方補考 2500/3hr。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 冬季班 上課地點 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">上課地點</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="100px" style="vertical-align:text-top;">
                                            <p>教室學科:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>iDiving士林前港店</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <p>泳池術科:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>各配合泳池（需先到iDiving集合）</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <p>海洋實習: </p>
                                        </td>
                                        <td class="fs-6">
                                            <p>夏季東北角（iDiving 東北角龍洞店）冬季潛立方（台中市西屯區安和西路 69 號）或 各移地結訓地</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <p>註: </p>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                ＊如遇天候不佳或泳池場地整修等，上課地點與時間可能視狀況調整，若有額外增加的場地費用，本中心將自行吸收（大台北區轉換場地之費用，台中泳池費用），不會另外收費。
                                                墾丁離島移地訓練產生之費用需自付。
                                                <br>＊學員不可因課程之"地點/時間"有調整而拒學並要求退費。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 冬季班 特約課程 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">特約課程</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>學科部份，每另外約一日課程加價 300 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <p>術科部份，每另外約一日課程加價 500 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>三人同行，一起約定特殊時間上課，不須額外加價。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>指定教練費: 指定教練，加價 500 元/日，教練如果已安排既定課程或是出團，恕無法指定。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 冬季班 更改辦法 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">更改辦法</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>課程須於開始上課後的兩個月內完成。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課日期之更改須在 10 天前告知</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 冬季班 取消辦法 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">取消辦法</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>若因身體因素而不適合從事潛水活動，並開立醫生證明者，繳交之費用退費方式如下: NT 3,000
                                                元訂金用做購買線上教材，無法退費。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                退費金額依據繳交之費用總額，扣除行政及教材費用各階段扣除費用參考如下:<br>
                                                學員自行放棄課程，本訓練中心將不予退費。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <br>

                            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 fs-6">
                                <ng-container matColumnDef="position">
                                    <th mat-header-cell *matHeaderCellDef class="fs-6"> </th>
                                    <td mat-cell *matCellDef="let element" class="fs-6"> {{element.position}} </td>
                                </ng-container>
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef class="fs-6"> 30天前，<br>退回全新贈品 </th>
                                    <td mat-cell *matCellDef="let element" class="fs-6"> {{element.name}} </td>
                                </ng-container>
                                <ng-container matColumnDef="weight">
                                    <th mat-header-cell *matHeaderCellDef class="fs-6"> 30天"內"，<br>退回全新贈品 </th>
                                    <td mat-cell *matCellDef="let element" class="fs-6"> {{element.weight}} </td>
                                </ng-container>
                                <ng-container matColumnDef="symbol">
                                    <th mat-header-cell *matHeaderCellDef class="fs-6"> 已上過學科 </th>
                                    <td mat-cell *matCellDef="let element" class="fs-6"> {{element.symbol}} </td>
                                </ng-container>
                                <ng-container matColumnDef="symbol1">
                                    <th mat-header-cell *matHeaderCellDef class="fs-6"> 已上過泳池 </th>
                                    <td mat-cell *matCellDef="let element" class="fs-6"> {{element.symbol1}} </td>
                                </ng-container>
                                <ng-container matColumnDef="symbol2">
                                    <th mat-header-cell *matHeaderCellDef class="fs-6"> 去過海洋 </th>
                                    <td mat-cell *matCellDef="let element" class="fs-6"> {{element.symbol2}} </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>

                            <br>

                        </div>
                    </ng-template>
                </li>
            </ul>

            <div [ngbNavOutlet]="nav" class="mt-2"></div>

            <!-- <pre>Active: {{ active }}</pre> -->
        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td></td>
            </tr>
            <tr>
                <td></td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>
</div>